import React, { FC, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import usePrefix from 'utils/usePrefix';
import { APP_URL } from 'App/constants';

import Prompt from 'components/Prompt';

const SavedPasswordPrompt: FC = () => {
  const t = usePrefix('Login');
  const navigate = useNavigate();

  const onSavedPassword = useCallback(() => {
    navigate(`/${APP_URL}`);
  }, [navigate]);

  return <Prompt text={t('password_saved')} label={t('logIn')} onClick={onSavedPassword} />;
};

export default memo(SavedPasswordPrompt);
