import React, { FC, memo, MouseEvent, ReactNode, useCallback } from 'react';
import { createPortal } from 'react-dom';

import usePrefix from 'utils/usePrefix';
import { LAYER_SIDE_PANEL_BACKDROP } from 'styles/layers';
import { Backdrop } from 'components/Backdrop';
import { CtaIcon } from 'components/_Redesign/CallToAction/styles';

import { MOUNT_NODE } from './constants';
import { SidePanelButtonClose, SidePanelCloseStyled, SidePanelStyled } from './styles';

export interface SidePanelProps {
  children?: ReactNode;
  className?: string;
  onClose: () => void;
}

const SidePanel: FC<SidePanelProps> = ({ children, className, onClose }) => {
  const t = usePrefix('General');

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const stopPropagation = useCallback((e: MouseEvent) => {
    e.stopPropagation();
  }, []);

  return createPortal(
    <>
      <SidePanelStyled className={className} onClick={stopPropagation}>
        <SidePanelCloseStyled>
          <SidePanelButtonClose onClick={handleClose} size="md" title={t('cta_close')}>
            <CtaIcon type="close" width="32" height="32" />
          </SidePanelButtonClose>
        </SidePanelCloseStyled>
        {children || null}
      </SidePanelStyled>
      <Backdrop zIndex={LAYER_SIDE_PANEL_BACKDROP} />
    </>,
    MOUNT_NODE,
  );
};

export default memo(SidePanel);
