import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import usePrefix from 'utils/usePrefix';
import {
  ChatType,
  ChatWithLastMessageResponse,
  ReporterDataEditingHistoryResponse,
} from 'utils/api/chats';
import { api, getApiUrl, ResponseError } from 'utils/api';
import { useApp } from 'App';
import { ActionTypes } from 'App/types';
import { notificationTypes } from 'utils/constants';
import { userRoles } from 'utils/userRoles';

import PageTitle from 'components/PageTitle';
import InputField from 'components/InputField';
import EmptyState from 'components/EmptyState';
import Button from 'components/_Redesign/Button';

import { PageTop } from '../styles';
import { ChatActionsStyled, NicknameContainer, NicknameInputContainer, Wrapper } from './styles';
import Panel from './Panel';
import ChatDownloadModal from './ChatDownloadModal';
import ReporterAdditionalInfo from './ChatAdditionalData';
import ChatRemovalModal from './ChatRemovalModal';
import ReporterDataHistoryModal from './ReporterDataHistoryModal';

const url = getApiUrl('/interveners/me/chats');

const MAX_NICKNAME_LENGTH = 63;

const validationSchema = (ty: (key: string, vars?: object) => string) =>
  Yup.object().shape({
    nickname: Yup.string().max(
      MAX_NICKNAME_LENGTH,
      ty('max_nickname_length_is', { length: MAX_NICKNAME_LENGTH }),
    ),
  });

interface Props {
  onClear: () => void;
  chatId?: number;
  chatCreationTime?: string;
  anonymous?: boolean;
  chatList: ChatWithLastMessageResponse[];
  nickname?: string | null;
  deactivationTime: string | null;
  onClearAndReset: () => void;
  chatNumber?: number;
  chatType?: ChatType;
  chatPadding: boolean;
  reporterNameAndLastName?: string;
  reporterEmail?: string;
  reporterCreationTime?: string;
  onChatRemove: () => void;
  reporterDataEditingHistory?: ReporterDataEditingHistoryResponse[];
  chat_case_number?: string;
}

interface FormProps {
  nickname: string | null;
}

const ChatPanel: React.FC<Props> = ({
  chatId,
  chatCreationTime,
  anonymous,
  onClear,
  nickname,
  deactivationTime,
  chatList,
  onClearAndReset,
  chatNumber,
  chatType,
  chatPadding,
  reporterNameAndLastName,
  reporterEmail,
  reporterCreationTime,
  onChatRemove,
  reporterDataEditingHistory,
  chat_case_number,
}) => {
  const [{ profile }, dispatch] = useApp();

  const isDirector = profile?.role === userRoles.director;

  const [editMode, setEditMode] = useState(false);
  const [chatDownloadModalIsOpen, setChatDownloadModalIsOpen] = useState(false);
  const [chatRemovalModalIsOpen, setChatRemovalModalIsOpen] = useState(false);
  const [userHistoryChangesModalIsOpen, setUserHistoryChangesModalIsOpen] = useState(false);

  const t = usePrefix('Chat');
  const ty = usePrefix('YupErrors');

  const handleRemoveChat = () => {
    setChatRemovalModalIsOpen(false);
    onChatRemove();
  };

  const onChatNameEdit = () => {
    setEditMode(true);
  };

  const onChatRemovalClose = () => {
    setChatRemovalModalIsOpen(false);
  };

  const onChatRemovalOpen = () => {
    setChatRemovalModalIsOpen(true);
  };

  const onChatDownloadOpen = () => {
    setChatDownloadModalIsOpen(true);
  };

  const onChatDownloadClose = () => {
    setChatDownloadModalIsOpen(false);
  };

  const onHistoryChangesOpen = () => {
    setUserHistoryChangesModalIsOpen(true);
  };

  const onHistoryChangesClose = () => {
    setUserHistoryChangesModalIsOpen(false);
  };

  const getPageTitle = (values: FormProps): string => {
    switch (true) {
      case !!values.nickname:
        return values.nickname as string;
      case chatType === 'MANUAL':
        return t('user_added_manual_number', { number: chatNumber });
      case !!chatNumber:
        return t('username_number', { number: chatNumber });
      default:
        return t('username');
    }
  };

  const submit = async (values: FormProps) => {
    if (values.nickname === nickname || (!values.nickname && !nickname)) {
      setEditMode(false);
      return;
    }

    try {
      const response = await api(`${url}/${chatId}`, {
        method: 'PUT',
        payload: { ...values },
      });

      if (response) {
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { type: notificationTypes.success },
        });
        setEditMode(false);
        onClear();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  return (
    <Wrapper
      justifyContent="space-between"
      alignItems="flex-start"
      alignContent="flex-start"
      flexDirection="column"
      chatPadding={chatPadding}
    >
      {chatId ? (
        <>
          <PageTop>
            <Formik
              initialValues={{
                nickname: nickname ? nickname : '',
              }}
              onSubmit={submit}
              enableReinitialize={true}
              validationSchema={() => validationSchema(ty)}
            >
              {({ submitForm, values, errors }) =>
                !editMode ? (
                  <>
                    <NicknameContainer>
                      <PageTitle title={getPageTitle(values)} />
                      <ChatActionsStyled role="group">
                        <Button
                          type="button"
                          onClick={onChatNameEdit}
                          icon="pencil"
                          size="md"
                          color="text-primary"
                          variant="text"
                          label={t('chat_edit')}
                        />
                        <Button
                          type="button"
                          onClick={onChatDownloadOpen}
                          icon="download"
                          size="md"
                          color="text-primary"
                          variant="text"
                          label={t('chat_download_button')}
                        />
                        <Button
                          type="button"
                          onClick={onHistoryChangesOpen}
                          icon="clockCounterClockwise"
                          size="md"
                          color="text-primary"
                          label={t('cta_check_history')}
                          variant="text"
                        />
                        {isDirector && (
                          <Button
                            type="button"
                            onClick={onChatRemovalOpen}
                            icon="trash"
                            size="md"
                            color="text-error"
                            variant="text"
                            label={t('chat_delete')}
                          />
                        )}
                      </ChatActionsStyled>
                      <ReporterAdditionalInfo
                        reporterNameAndLastName={reporterNameAndLastName}
                        reporterEmail={reporterEmail}
                        reporterCreationTime={reporterCreationTime}
                        chatCaseNumber={chat_case_number}
                      />
                    </NicknameContainer>
                  </>
                ) : (
                  <NicknameInputContainer>
                    <InputField
                      name="nickname"
                      autoFocus
                      onBlur={submitForm}
                      error={errors?.nickname}
                    />
                  </NicknameInputContainer>
                )
              }
            </Formik>
          </PageTop>

          <Panel
            chatId={chatId}
            deactivationTime={deactivationTime}
            anonymous={anonymous}
            chatList={chatList}
            onClearAndReset={onClearAndReset}
            nickname={nickname ? nickname : t('username')}
          />
          <ReporterDataHistoryModal
            isOpen={userHistoryChangesModalIsOpen}
            onClose={onHistoryChangesClose}
            reporterDataEditingHistory={reporterDataEditingHistory}
          />
          <ChatDownloadModal
            chatId={chatId}
            chatCreationTime={chatCreationTime}
            onClose={onChatDownloadClose}
            isOpen={chatDownloadModalIsOpen}
          />
          {isDirector && (
            <ChatRemovalModal
              isOpen={chatRemovalModalIsOpen}
              onClose={onChatRemovalClose}
              onRemove={handleRemoveChat}
            />
          )}
        </>
      ) : (
        <EmptyState title={t('no_data')} />
      )}
    </Wrapper>
  );
};

export default ChatPanel;
