import React from 'react';
import { RouteProps, Navigate } from 'react-router-dom';
import { UserType } from 'utils/api/users';

import { useApp } from 'App/index';
import { APP_URL } from 'App/constants';

interface Props {
  Component: React.ComponentType;
  requiredRole?: UserType[];
}

const PrivateRoute: React.FC<Props & RouteProps> = ({ Component, requiredRole }) => {
  const [{ isLoggedIn, profile }] = useApp();

  return isLoggedIn &&
    (!requiredRole?.length || (profile && requiredRole.includes(profile.role))) ? (
    <Component />
  ) : (
    <Navigate to={`/${APP_URL}`} />
  );
};

export default PrivateRoute;
