import React from 'react';
import { FormikProps } from 'formik';

import usePrefix from 'utils/usePrefix';

import {
  AccessPageForm,
  AccessPageFormActionsItemStyled,
  AccessPageFormActionsStyled,
} from 'components/AccessPage/AccessPageForm/styles';
import DisabledSavePasswordInput from 'components/AccessPage/DisabledSavePasswordInput';
import Button from 'components/_Redesign/Button';
import FormFieldGroup from 'components/_Redesign/FormFieldGroup';

export interface Props {
  code: string;
}

const Form: React.FC<Pick<FormikProps<Props>, 'errors' | 'touched' | 'isSubmitting'>> = ({
  errors,
  touched,
  isSubmitting,
  ...props
}) => {
  const t = usePrefix('Login');

  return (
    <>
      <AccessPageForm {...props} autoComplete="off">
        <DisabledSavePasswordInput />
        <FormFieldGroup
          name="code"
          error={touched.code && errors.code}
          label={t('mfa_code')}
          type="text"
          placeholder={t('mfa_placeholder')}
          autoComplete="off"
          pattern="\d*"
          autoFocus
        />
        <AccessPageFormActionsStyled>
          <AccessPageFormActionsItemStyled>
            <Button
              color="primary"
              shape="full"
              size="lg"
              label={t('logIn')}
              type="submit"
              isDisabled={isSubmitting}
            />
          </AccessPageFormActionsItemStyled>
        </AccessPageFormActionsStyled>
      </AccessPageForm>
    </>
  );
};

export default Form;
