import React from 'react';

import usePrefix from 'utils/usePrefix';

import PageTitle from 'components/PageTitle';
import Loader from 'components/Loader';
import Search from 'components/Search';
import Button from 'components/_Redesign/Button';
import EmptyState from 'components/EmptyState';

import {
  Header,
  Content,
  UsersWrapper,
  UsersList,
  GridRow,
  ButtonContainer,
  PageHeaderStyled,
} from './styles';

interface Props {
  children?: React.ReactNode;
  title: string;
  isLoading: boolean;
  isData: boolean;
  labelNoResults: string;
  labelLoader: string;
  labelButton?: string;
  filter: string;
  setFilter: (val: string) => void;
  setModal?: ({ isOpen }: { isOpen: boolean }) => void;
  resetFilter?: () => void;
  isSearchClearDisabled?: boolean;
}

const TableContainer: React.FC<Props> = ({
  title,
  isLoading,
  isData,
  setFilter,
  filter,
  labelLoader,
  labelNoResults,
  labelButton,
  setModal,
  children,
  resetFilter,
  isSearchClearDisabled,
}) => {
  const t = usePrefix('General');

  return (
    <>
      <Header>
        <GridRow>
          <PageTitle title={title} />
          <PageHeaderStyled>
            <Search
              name="filter"
              placeholder={t('search')}
              onChange={(e) => setFilter(e.target.value)}
              value={filter}
              onClear={resetFilter}
              isDisabled={isSearchClearDisabled}
              isResetShow={!isSearchClearDisabled}
            />
            {setModal && (
              <ButtonContainer justifyContent="flex-end" alignContent="center">
                <Button
                  label={labelButton}
                  onClick={() => setModal({ isOpen: true })}
                  color="primary"
                  size="lg"
                />
              </ButtonContainer>
            )}
          </PageHeaderStyled>
        </GridRow>
      </Header>
      <Content>
        <UsersWrapper>
          {isLoading && <Loader label={labelLoader} />}
          {!isLoading && !isData && <EmptyState title={labelNoResults} />}
          {!!isData && <UsersList>{children}</UsersList>}
        </UsersWrapper>
      </Content>
    </>
  );
};

export default TableContainer;
