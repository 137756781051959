import styled from 'styled-components';

export const ActionsListStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 16px;
`;

export const ActionsListItemStyled = styled.div`
  min-width: 0;
`;
