import React, { useEffect, useState, useRef } from 'react';

import { ChatWithLastMessageResponse, ReporterDataEditingHistoryResponse } from 'utils/api/chats';
import { getApiUrl, useApi } from 'utils/api/useApi';
import { chatlistTypes, chatTypes, notificationTypes } from 'utils/constants';
import { api, ChatListTypes, PaginatedList, ResponseError } from 'utils/api';
import usePrefix from 'utils/usePrefix';

import { ActionTypes } from 'App/types';
import { useApp } from 'App';
import Loader from 'components/Loader';
import FetchingMessage from 'components/FetchingMessage';

import ChatList from './ChatList';
import ChatPanel from './ChatPanel';
import ChatMeta from './ChatMeta';
import { ChatMetaWrapper, Wrapper } from './styles';
import NewChat from './NewChat';

const url = getApiUrl('/interveners/me/chats');
const directorUrl = getApiUrl(`/director/me/chats`);

export interface ListState {
  chatList: ChatWithLastMessageResponse[];
  page: number;
  size: number;
  query?: string;
}

const Chat: React.FC = () => {
  const t = usePrefix('Chat');
  const [, dispatch] = useApp();

  const PAGE_START = 0;
  const PAGE_SIZE = 20;
  const REFRESH_INTERVAL = 10000;
  const [displayedChat, setChat] = useState<ChatWithLastMessageResponse>();
  const [deactivationTime, setDeactivationTime] = useState<string | null>(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [listFilter, setListFilter] = useState<ChatListTypes>(chatlistTypes.all);
  const [chatPadding, setChatPadding] = useState(false);
  const [reporterDataEditingHistory, setReporterDataEditingHistory] = useState<
    ReporterDataEditingHistoryResponse[]
  >([]);
  const [queryFilter, setQueryFilter] = useState('');
  const [isAddingChat, setIsAddingChat] = useState(false);

  const [state, setState] = useState<ListState>({
    chatList: [],
    page: PAGE_START,
    size: PAGE_SIZE,
    query: '',
  });

  // eslint-disable-next-line no-magic-numbers
  const [newMessageDate, setNewMessageDate] = useState(0);
  const newMessageDateRef = useRef<number | null>(null);

  const MIN_QUERY_LENGTH = 1;
  const CATEGORY = `&category=${listFilter}`;
  const IS_QUERY = queryFilter.length < MIN_QUERY_LENGTH ? '' : `&query=${queryFilter}`;

  const { data, isLoading, fetchData } = useApi<PaginatedList<ChatWithLastMessageResponse[]>>(
    `${url}?page=${state.page}&size=${state.size}${CATEGORY}${IS_QUERY}`,
    {
      method: 'GET',
    },
  );

  const clearState = () =>
    setState((prevState) => ({
      ...prevState,
      page: PAGE_START,
      chatList: [],
    }));

  const onClearAndReset = () => {
    setFirstLoad(true);
    clearState();
  };

  const dataPoll = async () => {
    try {
      const response: PaginatedList<ChatWithLastMessageResponse[]> = await api(
        `${url}?page=${PAGE_START}&size=${PAGE_SIZE}&category=${chatlistTypes.all}`,
        {
          method: 'GET',
        },
      );

      if (response?.content.length) {
        const lastMessageTimeArray = response.content
          .map((chat) => new Date(chat?.last_message?.creation_time).getTime())
          .filter((time) => !isNaN(time));

        const newMaxMessageTime = Math.max(...lastMessageTimeArray);

        if (newMaxMessageTime !== newMessageDateRef.current) {
          newMessageDateRef.current = newMaxMessageTime;
          setNewMessageDate(newMaxMessageTime);
        }
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const deleteChat = async () => {
    if (!displayedChat?.id) {
      return;
    }
    const url = `${directorUrl}/${displayedChat?.id}`;
    try {
      const response = await fetch(url, {
        method: 'DELETE',
      });
      // eslint-disable-next-line no-magic-numbers
      if (response.status === 204) {
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { type: notificationTypes.success },
        });
        setState((prevState) => {
          const newChatList = prevState.chatList.filter((chat) => chat.id !== displayedChat?.id);
          setChat(newChatList[0]);
          return {
            ...prevState,
            chatList: newChatList,
          };
        });
        return true;
      } else {
        throw new Error('Failed to delete chat');
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
      return false;
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      dataPoll();
    }, REFRESH_INTERVAL);

    return () => clearInterval(intervalId as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.content?.length) {
      if (!state.chatList.length) {
        const lastMessageTimeArray = data.content.map((chat) =>
          new Date(chat?.last_message?.creation_time).getTime(),
        );
        // eslint-disable-next-line no-magic-numbers
        if (listFilter === chatlistTypes.all) setNewMessageDate(Math.max(...lastMessageTimeArray));
      }
      setState((prevState) => ({
        ...prevState,
        chatList: state.page ? [...prevState.chatList, ...data.content] : [...data.content],
      }));
      // eslint-disable-next-line no-magic-numbers
      if (!displayedChat) setChat(data.content[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!!data && !state.chatList.length && state.page === PAGE_START) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.page, state.chatList]);

  useEffect(() => {
    if (newMessageDate) {
      if (firstLoad) {
        setFirstLoad(false);
        return;
      }
      clearState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessageDate]);

  const onChatClick = (chatId: number) => {
    setIsAddingChat(false);
    setDeactivationTime(null);
    setChat(state.chatList.find((chat) => chatId === chat.id));
  };

  const loadMore = () => {
    // eslint-disable-next-line no-magic-numbers
    if (!isLoading) setState((prevState) => ({ ...prevState, page: prevState.page + 1 }));
  };

  const handleInactiveChat = (deactivation_time: string | null) =>
    setDeactivationTime(deactivation_time);

  const handleListFilter = (filter: ChatListTypes) => {
    setListFilter(filter);
    clearState();
  };

  const handleQueryReset = () => {
    setQueryFilter('');
  };

  const handleQuery = (query: string) => {
    setQueryFilter(query);
    if (query.length >= MIN_QUERY_LENGTH) {
      clearState();
    }
  };

  const handleSuccessChatAdd = () => {
    setIsAddingChat(false);
    clearState();
  };

  const onNewChatAddOpen = () => {
    setIsAddingChat(true);
  };

  const onNewChatAddClose = () => {
    setIsAddingChat(false);
  };

  if (!data) return <FetchingMessage title={t('fetching_meta_data')} />;

  return (
    <Wrapper alignItems="stretch">
      {isLoading ? <Loader label={t('fetching_meta_data')} /> : null}
      <ChatList
        setChatId={onChatClick}
        chatId={displayedChat?.id}
        chatList={state.chatList}
        totalElements={data?.totalElements}
        PAGE_START={PAGE_START}
        PAGE_SIZE={PAGE_SIZE}
        loadMore={loadMore}
        currentPage={state.page}
        listFilter={listFilter}
        handleListFilter={handleListFilter}
        setQuery={handleQuery}
        query={queryFilter}
        resetQuery={handleQueryReset}
        isSearchClearDisabled={queryFilter.length < 1}
        onAddChat={onNewChatAddOpen}
        isAddingChat={isAddingChat}
        isLoading={isLoading}
      />
      <ChatPanel
        chatList={state.chatList}
        chatId={displayedChat?.id}
        chatCreationTime={displayedChat?.chat_creation_time}
        nickname={displayedChat?.nickname}
        chatNumber={displayedChat?.chat_number}
        chatType={displayedChat?.type}
        anonymous={displayedChat?.type === chatTypes.anonymous}
        onClear={clearState}
        onClearAndReset={onClearAndReset}
        deactivationTime={deactivationTime}
        chatPadding={chatPadding}
        reporterEmail={displayedChat?.reporter_email}
        reporterNameAndLastName={displayedChat?.reporter_name_and_last_name}
        reporterCreationTime={displayedChat?.reporter_creation_time}
        onChatRemove={deleteChat}
        reporterDataEditingHistory={reporterDataEditingHistory}
        chat_case_number={displayedChat?.chat_case_number}
      />
      <ChatMetaWrapper>
        {displayedChat?.id && (
          <ChatMeta
            chatId={displayedChat?.id}
            onClear={clearState}
            handleInactiveChat={handleInactiveChat}
            deactivationTime={deactivationTime}
            setChatPadding={setChatPadding}
            setReporterDataEditingHistory={(data) => setReporterDataEditingHistory(data)}
          />
        )}
      </ChatMetaWrapper>

      {isAddingChat && (
        <NewChat onChatAdd={handleSuccessChatAdd} onChatCancel={onNewChatAddClose} />
      )}
    </Wrapper>
  );
};

export default Chat;
