import React, { FC, memo } from 'react';

import usePrefix from 'utils/usePrefix';
import { getApiUrl } from 'utils/api';

import { QRCodeGeneratorStyled, QRCodeStyled } from './styles';

const url = getApiUrl('/users/me/mfa-code');

const QRCodeGenerator: FC = () => {
  const t = usePrefix('Mfa');

  return (
    <QRCodeGeneratorStyled>
      <h3>{t('config_title')}</h3>
      <p>{t('download_app_on_mobile_phone')}:</p>
      <ol>
        <li>{t('ms_authenticator')},</li>
        <li>{t('google_authenticator')}.</li>
      </ol>
      <p>{t('scan_qr_code_with_app')}:</p>
      <QRCodeStyled>
        <img src={url} alt={t('qr_code')} />
      </QRCodeStyled>
    </QRCodeGeneratorStyled>
  );
};

export default memo(QRCodeGenerator);
