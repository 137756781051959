import React, { memo } from 'react';

import usePrefix from 'utils/usePrefix';
import { userRoles } from 'utils/userRoles';

import { useApp } from 'App';
import MfaConfiguration from 'components/MFAConfiguration';

const MfaDetails = () => {
  const t = usePrefix('General');
  const [{ profile, schools }] = useApp();

  const isMfaMandatory = schools.find((item) => item.mfa_mandatory === true);

  const isMfaConfigShow = profile?.role !== userRoles.admin && isMfaMandatory;

  return isMfaConfigShow ? <MfaConfiguration ctaLabel={t('mfa_configuration')} /> : null;
};

export default memo(MfaDetails);
