export enum ActionTypes {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  GET_VIOLENCE_TYPES = 'GET_VIOLENCE_TYPES',
  SET_NOTIFICATION_CODE = 'SET_NOTIFICATION_CODE',
  REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',
  GET_SCHOOLS = 'GET_SCHOOLS',
  TO_MANY_USER_LOGIN_ATTEMPTS = 'TO_MANY_USER_LOGIN_ATTEMPTS',
  WWW_BRANDING_LOGO_NOT_EXIST = 'WWW_BRANDING_LOGO_NOT_EXIST',
  TOTP_CODE_LOGIN = 'TOTP_CODE_LOGIN',
  TOTP_CODE_REQUIRED = 'TOTP_CODE_REQUIRED',
  TOTP_CODE_INVALID = 'TOTP_CODE_INVALID',
}
