import React, { FC, memo, useState } from 'react';

import AssignmentDetails from 'pages/Assignments/AssignmentDetails';

import { getApiUrl } from 'utils/api';
import usePrefix from 'utils/usePrefix';
import { useApi } from 'utils/api/useApi';
import { UsersInCompanyResponse, UserType } from 'utils/api/assignments';

import Button from 'components/_Redesign/Button';
import PageTitle from 'components/PageTitle';
import {
  TableDataStyled,
  TableHeaderStyled,
  TableRowStyled,
  TableStyled,
} from 'components/Table/styles';
import FetchingMessage from 'components/FetchingMessage';

import {
  AssignmentsContentStyled,
  AssignmentsPageStyled,
  AssignmentsHeaderStyled,
  CompanyContainer,
  CompanyTitleStyled,
} from './styles';

export interface AssignmentModalState {
  isOpen: boolean;
  userFirstName?: string | null;
  userLastName?: string | null;
  userType?: UserType;
  companyId?: number;
  userId?: number;
}

const Assignments: FC = () => {
  const t = usePrefix('Assignments');
  const tg = usePrefix('General');

  const [modal, setModal] = useState<AssignmentModalState>({ isOpen: false });

  const url = getApiUrl('/director/me/management-users-by-company');

  const { data, isLoading } = useApi<UsersInCompanyResponse>(url, {
    method: 'GET',
  });

  if (!data) {
    return <FetchingMessage title={t('fetching_company_users_list')} />;
  }

  return (
    <AssignmentsPageStyled>
      <AssignmentsHeaderStyled>
        <PageTitle title={t('assignments')} />
      </AssignmentsHeaderStyled>

      <AssignmentsContentStyled>
        {Object.keys(data.company_to_users).map((companyId) => {
          const company = data.company_to_users[companyId];

          return (
            <CompanyContainer key={companyId}>
              <CompanyTitleStyled>
                {t('users_associated_with')} {company.company_name}
              </CompanyTitleStyled>
              <TableStyled>
                <thead>
                  <TableRowStyled>
                    <TableHeaderStyled>{t('name_and_surname')}</TableHeaderStyled>
                    <TableHeaderStyled>{t('role')}</TableHeaderStyled>
                    <TableHeaderStyled></TableHeaderStyled>
                  </TableRowStyled>
                </thead>
                <tbody>
                  {company.users.map((user) => (
                    <TableRowStyled key={user.id}>
                      <TableDataStyled>
                        {user.first_name} {user.last_name}
                      </TableDataStyled>
                      <TableDataStyled>{tg(user.role)}</TableDataStyled>
                      <TableDataStyled>
                        <Button
                          color="secondary"
                          size="md"
                          icon="pencil"
                          label={t('permissions')}
                          isDisabled={isLoading}
                          onClick={() =>
                            setModal({
                              isOpen: true,
                              companyId: company.company_id,
                              userId: user.id,
                              userType: user.role,
                              userFirstName: user.first_name,
                              userLastName: user.last_name,
                            })
                          }
                        />
                      </TableDataStyled>
                    </TableRowStyled>
                  ))}
                </tbody>
              </TableStyled>
            </CompanyContainer>
          );
        })}
      </AssignmentsContentStyled>

      {modal.isOpen && (
        <AssignmentDetails
          userId={modal.userId}
          companyId={modal.companyId}
          isOpen={modal.isOpen}
          userType={modal.userType || null}
          onClose={() => setModal({ isOpen: false })}
          userFirstName={modal.userFirstName || null}
          userLastName={modal.userLastName || null}
        />
      )}
    </AssignmentsPageStyled>
  );
};

export default memo(Assignments);
