/* eslint-disable no-magic-numbers */
import styled from 'styled-components';

import { calcSize } from 'styles/calcSize';

export const QRCodeGeneratorStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  h3 {
    font-weight: 700;
    font-size: ${calcSize(16)};
    line-height: 1.65;
    letter-spacing: ${calcSize(0.4)};
    color: var(--access-page-card-txt);
    text-align: left;
  }

  ul,
  ol {
    margin-block: 16px;
  }

  p,
  li {
    font-weight: 400;
    font-size: ${calcSize(14)};
    line-height: 1.65;
    letter-spacing: ${calcSize(0.4)};
    color: var(--access-page-card-txt);
    text-align: left;
  }
`;

export const QRCodeStyled = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;
