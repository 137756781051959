import React, { memo } from 'react';

import usePrefix from 'utils/usePrefix';

import Modal from 'components/_Redesign/Modal';
import {
  ModalActionsStyled,
  ModalBodyStyled,
  ModalHeaderStyled,
} from 'components/_Redesign/Modal/styles';
import Button from 'components/_Redesign/Button';

import QRCodeGenerator from 'components/QRCodeGenerator';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const MFAModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const t = usePrefix('Mfa');
  const tg = usePrefix('General');

  return isOpen ? (
    <Modal onClose={onClose} align="center" size="md">
      <ModalHeaderStyled>
        <h3>{t('mfa_title')}</h3>
      </ModalHeaderStyled>
      <ModalBodyStyled>
        <QRCodeGenerator />
      </ModalBodyStyled>
      <ModalActionsStyled>
        <Button onClick={onClose} color="secondary" size="lg" label={tg('cta_close')} />
      </ModalActionsStyled>
    </Modal>
  ) : null;
};

export default memo(MFAModal);
