/* eslint-disable no-magic-numbers */
import styled from 'styled-components';
import { Form } from 'formik';

import { calcSize } from 'styles/calcSize';

export const AccessPageForm = styled(Form)`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-inline: auto;
  width: 100%;
  max-width: 336px;
`;

export const AccessPageFormActionsStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;
  justify-items: center;
  align-items: flex-start;
  margin-inline: auto;
  width: 100%;
  max-width: 336px;
`;

export const AccessPageFormActionsItemStyled = styled.div`
  width: 100%;
  text-align: center;
`;

export const AccessPageLockedAccountStyled = styled.div`
  margin-inline: auto;
  padding: 12px;
  border-radius: 8px;
  width: 100%;
  max-width: 336px;
  background: var(--state-error-bg);
  font-weight: 400;
  font-size: ${calcSize(14)};
  line-height: 1.2;
  color: var(--state-error-txt);
  white-space: pre-line;
`;
