import React, { FC, memo, useState } from 'react';

import Button from 'components/_Redesign/Button';
import { CtaShape } from 'components/_Redesign/CallToAction/types';

import MFAModal from './MFAModal';

interface Props {
  ctaLabel: string;
  ctaShape?: CtaShape;
}

const MfaConfiguration: FC<Props> = ({ ctaLabel, ctaShape }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const onModalOpen = () => {
    setModalOpen(true);
  };

  const onModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Button
        type="button"
        onClick={onModalOpen}
        size="lg"
        color="secondary"
        shape={ctaShape}
        label={ctaLabel}
      />
      <MFAModal isOpen={isModalOpen} onClose={onModalClose} />
    </>
  );
};

export default memo(MfaConfiguration);
