import React, { memo } from 'react';
import { Formik } from 'formik';

import { getApiUrl, api, ResponseError } from 'utils/api';
import { useApi } from 'utils/api/useApi';
import usePrefix from 'utils/usePrefix';
import { GetUsersResponse, PostUserRequest } from 'utils/api/users';
import { userValidationSchema } from 'utils/validationSchema';
import { useApp } from 'App';
import { ActionTypes } from 'App/types';
import { notificationTypes } from 'utils/constants';

import PageTitle from 'components/PageTitle';
import FetchingMessage from 'components/FetchingMessage';

import Form from './Form';
import { Wrapper, ProfileStyled, ProfileTitle, ProfileDataStyled } from './styles';
import MfaDetails from './MFADetails';

const url = getApiUrl('/users/me');

const Profile: React.FC = () => {
  const [, dispatch] = useApp();

  const t = usePrefix('General');
  const ty = usePrefix('YupErrors');

  const { data, isLoading } = useApi<GetUsersResponse>(url, {
    method: 'GET',
  });

  const submit = async (values: PostUserRequest) => {
    try {
      const response = await api(url, {
        method: 'PUT',
        payload: {
          ...values,
          phone: values.phone ? values.phone : null,
        },
      });
      if (response)
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { code: 'PROFILE_UPDATE', type: notificationTypes.success },
        });
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  return (
    <Wrapper justifyContent="flex-start" alignItems="flex-start" alignContent="flex-start">
      <ProfileStyled>
        <ProfileTitle>
          <PageTitle title={t('your_profile')} />
        </ProfileTitle>
        {!isLoading ? (
          !!data && (
            <ProfileDataStyled>
              <Formik
                initialValues={{ ...data, phone: data.phone ? data.phone : '' }}
                onSubmit={submit}
                validationSchema={() => userValidationSchema(ty)}
                enableReinitialize={true}
              >
                {({ errors, touched, isSubmitting, values }) => (
                  <Form
                    errors={errors}
                    touched={touched}
                    isSubmitting={isSubmitting}
                    values={values}
                  />
                )}
              </Formik>
              <MfaDetails />
            </ProfileDataStyled>
          )
        ) : (
          <FetchingMessage title={t('fetching')} />
        )}
      </ProfileStyled>
    </Wrapper>
  );
};

export default memo(Profile);
