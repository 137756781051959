import React, { FC, memo } from 'react';

import usePrefix from 'utils/usePrefix';
import { APP_URL } from 'App/constants';

import ButtonLink from 'components/_Redesign/ButtonLink';

const BackToLoginButton: FC = () => {
  const t = usePrefix('Login');

  return (
    <ButtonLink
      color="text-primary"
      label={t('back_to_login')}
      size="md"
      url={`/${APP_URL}`}
      variant="text"
    />
  );
};

export default memo(BackToLoginButton);
